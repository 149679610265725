/* Sections */

h1, h2, h3, h4, h5, h6 {
    color: var(--color-text);
  }  

.color-section {
    background: var(--color-footer);
  }


.protactics__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}

.protactics__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.protactics__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 35px;
    line-height: 35px;
}

.protactics__footer-heading h2 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    line-height: 25px;
}
.protactics__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;

    margin-bottom: 10rem;
    cursor: pointer;
}

.protactics__footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
    word-spacing: 2px;
}

.protactics__footer-center {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: center;
}

.protactics__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.protactics__footer-links div {
    width: 200px;
    margin: 1rem;
}

.protactics__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.protactics__footer-links_logo img {
    width: 118px;
    height: 30px;

    margin-bottom: 1rem;
}

.protactics__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.protactics__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.protactics__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}

.protactics__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

/* Datenschutz Section */

#datenschutz {
    position: relative;
    padding: 100px;
  }
  
  .datenschutz-subtext {
    color: var(--color-text);
  }

  h1, h2, h3, h4 {
    color: #fff;
  }  



.protactics__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.protactics__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .protactics__footer-heading h1 {
        font-size: 12px;
        line-height: 12px;
    }
}
@media screen and (max-width: 850px) {
    .protactics__footer-heading h2 {
        font-size: 10px;
        line-height: 10px;
    }
}
@media screen and (max-width: 850px) {
    .protactics__footer-heading h3 {
        font-size: 9px;
        line-height: 9px;
    }
}

@media screen and (max-width: 550px) {
    .protactics__footer-heading h1 {
        font-size: 10px;
        line-height: 10px;
    }
    @media screen and (max-width: 550px) {
        .protactics__footer-heading h2 {
            font-size: 9px;
            line-height: 9px;
        }    
    }
    @media screen and (max-width: 550px) {
        .protactics__footer-heading h3 {
            font-size: 8px;
            line-height: 8px;
        }    
    }
    .protactics__footer-links div {
        margin: 1rem 0;
    }

    .protactics__footer-btn p {
        font-size: 9px;
        line-height: 9px;
    }
}



@media screen and (max-width: 400px) {
    .protactics__footer-heading h1 {
        font-size: 10px;
        line-height: 10px;
    }
}

@media screen and (max-width: 400px) {
    .protactics__footer-heading h2 {
        font-size: 9px;
        line-height: 9px;
    }
}
@media screen and (max-width: 400px) {
    .protactics__footer-heading h3 {
        font-size: 8px;
        line-height: 8px;
    }
}
