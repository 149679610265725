.protactics__blog {
    display: flex;
    flex-direction: column;    
}

.protactics__blog-heading {
    width: 100%;
    text-align: left;

    margin-bottom: 5rem;
}

.protactics__blog-heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.protactics__blog-container {
    display: flex;
    flex-direction: row;
}

.protactics__blog-container_groupA {
    flex: 0.65;
    margin-right: 2rem;
}

.protactics__blog-container_groupB {
    flex: 0.8;

    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

@media screen and (max-width: 990px) {
    .protactics__blog-container {
        flex-direction: column-reverse;
    }


    .protactics__blog-container_groupA {
        margin: 2rem 0;
    }

    .protactics__blog-container_groupA .protactics__blog-container_article  {
       width: 48%;
    }

    .protactics__blog-container_groupA .protactics__blog-container_article-image {
        height: 200px;
    }
}

#features {
    position: relative;
    color: aquamarine;
  }
  
  .bg-custom-1 {
    background-color: blue;
    background: var(--color-footer);
    text-align: center;
}

.bg-custom-2 {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: aquamarine;
    cursor: pointer;
    outline: none;
    text-align: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

  .feature-title {
    font-size: 1.5rem;
  }

  .feature-box {
    padding: 4.5%;
 }
  
  .icon {
    color: #ef8172;
    margin-bottom: 1rem;
  }

.txt-pink { color: #176B8F; margin: 1em 40px;}
.txt-green { color: #6dc82b; }
.txt-orange { color: #fe8000; }
.txt-blue { color: #1abac8; }


@media screen and (max-width: 700px) {
    .protactics__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .protactics__blog-container_groupA .protactics__blog-container_article  {
       width: 100%;
    }

    .protactics__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .protactics__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}