@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #78cff5 1.84%, #55e631 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #a0c6ec;
  --color-subtext: #FF8A71;
}

@media screen and (max-width: 850px) {
  .protactics__footer-heading h1 {
      font-size: 65px;
      line-height: 50px;
  }
}

@media screen and (max-width: 550px) {
  .protactics__footer-heading h1 {
      font-size: 50px;
      line-height: 42px;
  }

  .protactics__footer-links div {
      margin: 1rem 0;
  }

  .protactics__footer-btn p {
      font-size: 14px;
      line-height: 20px;
  }
}

@media screen and (max-width: 400px) {
  .protactics__footer-heading h1 {
      font-size: 35px;
      line-height: 38px;
  }
}
