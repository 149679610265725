.protactics__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}

.protactics__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.protactics__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}



.protactics__footer-btn {
    background: var(--color-footer);

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    -webkit-border-radius: 8;
    -moz-border-radius: 8;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;
    font-size: 20px;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
  }

.protactics__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.protactics__footer-links div {
    width: 250px;
    margin: 1rem;
}

.protactics__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.protactics__footer-links_logo img {
    width: 118px;
    height: 30px;

    margin-bottom: 1rem;
}

.protactics__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.protactics__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.protactics__footer-links_div h2 {
    font-size: 26px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}

.protactics__footer-links_div p {
    font-size: 20px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.protactics__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.protactics__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .protactics__footer-heading h1 {
        font-size: 65px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .protactics__footer-heading h1 {
        font-size: 50px;
        line-height: 42px;
    }

    .protactics__footer-links div {
        margin: 1rem 0;
    }

    .protactics__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .protactics__footer-heading h1 {
        font-size: 35px;
        line-height: 38px;
    }
}
