.protactics__blog-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
    background: #FF4820;
}

.protactics__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.protactics__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.protactics__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.protactics__blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 11.65px;
    font-weight: 700;
    line-height: 35px;
    
    color: #fff;
}

.protactics__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 25.11px;
    font-weight: 800;
    line-height: 30.30px;
    
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.protactics__blog-container_article-content p:last-child {
    cursor: pointer;
}

.bg-custom-2 {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

@media screen and (max-width: 550px) {
    .protactics__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
    .bg-custom-2 {
        font-size: 20px;
        line-height: 28px;
    }    
}